import React from 'react'

const LinkedinSvg = ({fill = '#cad4e0'}) => {
  return (
    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 14H.2187V4.6562H3.125V14zM1.6875 3.375c-.4583 0-.8542-.1667-1.1875-.5-.3333-.3333-.5-.7292-.5-1.1875C0 1.2292.1667.8333.5.5.8333.1667 1.2292 0 1.6875 0c.4583 0 .8542.1667 1.1875.5.3333.3333.5.7292.5 1.1875 0 .4583-.1667.8542-.5 1.1875-.3333.3333-.7292.5-1.1875.5zM14 14h-2.9063V9.4375c0-.75-.0625-1.2917-.1875-1.625-.2291-.5625-.677-.8438-1.3437-.8438-.6667 0-1.1354.25-1.4063.75-.2083.375-.3125.9271-.3125 1.6563V14h-2.875V4.6562H7.75v1.2813h.0312c.2084-.4167.5417-.7604 1-1.0313.5-.3333 1.0834-.5 1.75-.5 1.3542 0 2.3021.4271 2.8438 1.2813.4166.6875.625 1.75.625 3.1875V14z"
        fill={fill}
      />
    </svg>
  )
}

export default LinkedinSvg
