import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Disqus from 'disqus-react'
import styled from 'styled-components'

const CommentsWrapper = styled.div`
  padding: 20px;
  background: ${props => props.theme.white};
  border-radius: 3px;
  box-shadow: 0 2px 2px 2px #00000008;
  @media (max-width: 600px) {
    border-radius: 0;
  }
`

const DisqusCommentsWrapper = ({title, uri, id}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const fullUrl = data.site.siteMetadata.siteUrl + '/blog/' + uri

  const disqusShortname = 'kooi'
  const disqusConfig = {
    url: fullUrl,
    identifier: id,
    title,
  }

  return (
    <CommentsWrapper>
      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </CommentsWrapper>
  )
}
export default DisqusCommentsWrapper
