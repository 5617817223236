import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {Inner} from '../../styles'
import {decodeHtmlEncodedString} from '../../../utils'

const BlogPageHeaderStyled = styled.div`
  background: ${({theme, featuredImage}) =>
    featuredImage && featuredImage.sourceUrl
      ? `linear-gradient(#00000040, #00000040), url('${featuredImage.sourceUrl}')`
      : theme.grey1};
  background-size: cover;
  background-position: center;
  color: ${props => props.theme.grey1};
  margin-bottom: ${props => props.theme.vPadding};
  text-align: center;
  padding: 30px 0;
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  h1 {
    color: ${props => props.theme.white};
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`
const Breadcrumbs = styled.div`
  span,
  a {
    text-transform: capitalize;
    color: ${props => props.theme.white};
    padding: 10px 10px 10px 12px;
    position: relative;
    &:not(:first-child):before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid ${props => props.theme.white};
      opacity: 0.5;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: calc(50% - 5px);
      left: 0;
    }
    text-decoration: none;
  }
  a:hover {
    /* color: ${props => props.theme.redHover}; */
    text-decoration: underline;
  }
`

const MetaInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  padding: 20px 0;

  & img {
    border-radius: 500px;
    width: 60px;
    margin-right: 20px;
  }

  & p:first-child {
    opacity: 1;
  }

  & p {
    color: white;
    opacity: 0.7;
    margin: 0;
    text-align: left;
  }
`

const BlogPageHeader = ({children, location, featuredImage, author, date}) => {
  const paths = location.pathname.split('/')
  // remove blanks
  const crumbs = paths.filter(el => el !== '')
  // remove 'App'
  const index = crumbs.indexOf('app')
  if (index > -1) {
    crumbs.splice(index, 1)
  }

  return (
    <BlogPageHeaderStyled featuredImage={featuredImage}>
      <Inner>
        <h1>{children}</h1>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          {crumbs.map((crumb, i) =>
            crumbs.length > 1 && i === 0 ? (
              <Link to={`/${crumb}/`} key={crumb}>
                {crumb.replace(/-/g, ' ')}
              </Link>
            ) : (
              <span key={crumb}>{crumb.replace(/-/g, ' ')}</span>
            ),
          )}
        </Breadcrumbs>
        <MetaInfo>
          <img src={author.avatar.url} alt="avatar" />
          <div>
            <p>{decodeHtmlEncodedString(author.name)}</p>
            <p>{new Date(date).toLocaleDateString()}</p>
          </div>
        </MetaInfo>
      </Inner>
    </BlogPageHeaderStyled>
  )
}

export default BlogPageHeader
