import React, {Fragment} from 'react'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'

import {graphql, Link} from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CategoriesWidget from '../components/Blog/CategoriesWidget'
import RecentPostsWidget from '../components/Blog/RecentPostsWidget'
import BlogPageHeader from '../components/Layout/BlogPageHeader'
import {
  PageLayout,
  MainContent,
  Sidebar,
  StickySidebarContent,
} from '../components/Blog/Layout'
import SocialButtonsWrapper from '../components/Blog/SocialSharingButtons'
import DisqusCommentsWrapper from '../components/Blog/DisqusCommentsWrapper'
import {decodeHtmlEncodedString} from '../utils'

const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

const Container = styled.div`
  background: ${props => props.theme.white};
  color: ${props => props.theme.grey1};
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 2px #00000008;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.grey1};
  }
  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }
`
const ContentContainer = styled.div`
  padding: 30px;
`

const renderTermNodes = (nodes, title) => (
  <div>
    {title}
    {` `}
    {nodes.map(term => (
      <Label as={Link} to={`/category/${term.slug}/`} key={term.name}>
        {term.name}
      </Label>
    ))}
  </div>
)

// const renderTerms = (categoryNodes = [], tagNodes = []) => (
//   <Fragment>
//     <Divider />
//     {categoryNodes && renderTermNodes(categoryNodes, `Categories: `)}
//   </Fragment>
// )

// A single blog post page
const WordPressPost = props => {
  const {
    location,
    data: {
      wpgraphql: {post},
    },
    pageContext,
  } = props

  const {content, featuredImage, author, uri} = post

  const title = decodeHtmlEncodedString(post.title)
  const excerpt = decodeHtmlEncodedString(post.excerpt)
  const date = decodeHtmlEncodedString(post.date)

  return (
    <Layout location={location} background="#fafafa">
      <SEO
        location={location}
        title={title}
        imageSrc={featuredImage.sourceUrl}
        htmldescription={excerpt}
      />
      <BlogPageHeader
        location={location}
        featuredImage={featuredImage}
        author={author}
        date={date}
      >
        {title}
      </BlogPageHeader>
      <Inner>
        <PageLayout>
          <MainContent>
            <Container>
              <ContentContainer dangerouslySetInnerHTML={{__html: content}} />
            </Container>
            <SocialButtonsWrapper title={title} url={uri} excerpt={excerpt} />
            <DisqusCommentsWrapper
              title={title}
              uri={uri}
              id={pageContext.id}
            />
          </MainContent>
          <Sidebar>
            <StickySidebarContent>
              <CategoriesWidget />
              <RecentPostsWidget />
            </StickySidebarContent>
          </Sidebar>
        </PageLayout>
      </Inner>
    </Layout>
  )
}

export default WordPressPost

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wpgraphql {
      post(id: $id) {
        title
        content
        excerpt
        uri
        date
        featuredImage {
          sourceUrl
        }
        author {
          name
          slug
          avatar {
            url
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`
